import type { IHttpClient } from '@wix/fe-essentials-viewer-platform/http-client'
import { SessionServiceAPI } from '@wix/thunderbolt-symbols'
import { Fieldset } from '../types'
import { Set, Member } from './memberTypes'
import { toVeloMemberV2 } from './memberMapper'

export class MembersSdk {
	constructor(private readonly sessionService: SessionServiceAPI, private readonly httpClient: IHttpClient) {}

	public async getMyMember(sets?: Array<Fieldset>) {
		const fieldsets = sets?.map((fieldset) => (fieldset === 'FULL' ? Set.FULL : Set.PUBLIC))
		const { data } = await this.httpClient.get<{ member: Member }>('/_api/members/v1/members/my', {
			params: { fieldsets },
			headers: this.getHeaders(),
		})
		return toVeloMemberV2(data.member)
	}

	public async joinCommunity() {
		const { data } = await this.httpClient.post<{ member: Member }>(
			'/_api/members/v1/members/join-community',
			{},
			{
				headers: this.getHeaders(),
			}
		)
		return toVeloMemberV2(data.member)
	}

	public async leaveCommunity() {
		const { data } = await this.httpClient.post<{ member: Member }>(
			'/_api/members/v1/members/leave-community',
			{},
			{
				headers: this.getHeaders(),
			}
		)
		return toVeloMemberV2(data.member)
	}

	private getHeaders() {
		const headers = {
			'x-wix-client-artifact-id': 'thunderbolt',
			authorization: this.sessionService.getWixCodeInstance(),
		}
		return headers
	}
}
