import { Member } from '@wix/ambassador-members-ng-api/http'
import { Member as MemberV2 } from './memberTypes'
import { VeloMember, VeloMemberV2 } from '../types'

export const toVeloMemberV2 = (member?: MemberV2): VeloMemberV2 | undefined => {
	if (member === undefined) {
		return undefined
	}
	return {
		_id: member.id,
		contactId: member.contactId,
		loginEmail: member.loginEmail,
		profile: {
			nickname: member.profile?.nickname,
			slug: member.profile?.slug,
			profilePhoto: member.profile?.photo,
			coverPhoto: member.profile?.cover,
			title: member.profile?.title,
		},
		contactDetails: member.contact,
		activityStatus: member.activityStatus,
		privacyStatus: member.privacyStatus,
		status: member.status,
		lastLoginDate: member.lastLoginDate,
		_createdDate: member.createdDate,
		_updatedDate: member.updatedDate,
	}
}

/**
 * Move to toVeloMember
 * @deprecated
 */
export const toVeloMember = (member?: Member | undefined): VeloMember | undefined => {
	if (member === undefined) {
		return undefined
	}
	return {
		_id: member.id,
		contactId: member.contactId,
		loginEmail: member.loginEmail,
		profile: {
			nickname: member.profile?.nickname,
			slug: member.profile?.slug,
			profilePhoto: member.profile?.photo,
			coverPhoto: member.profile?.cover,
			title: member.profile?.title,
		},
		contactDetails: member.contact,
		activityStatus: member.activityStatus,
		privacyStatus: member.privacyStatus,
		status: member.status,
		lastLoginDate: member.lastLoginDate,
		_createdDate: member.createdDate,
		_updatedDate: member.updatedDate,
	}
}
