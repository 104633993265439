import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace } from '../symbols'
import type { TelemetryWixCodeSdkWixCodeApi } from '../types'

/**
 * This is SDK Factory.
 * Expose your Velo API
 */
export function TelemetrySdkFactory({
	appDefinitionId,
}: WixCodeApiFactoryArgs): {
	[namespace]: TelemetryWixCodeSdkWixCodeApi
} {
	return {
		[namespace]: {
			someKey: appDefinitionId,
			doSomething: () => {},
		},
	}
}
